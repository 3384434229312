// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as H2 from "../../../../../styleguide/components/Heading/H2.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Breadcrumb from "../../../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_LocationBrochure from "../../../../../api/location-brochures/Api_LocationBrochure.res.js";
import * as SignInModalContainer from "../../../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";
import * as Routes_LocationBrochure from "../../../../../routes/common/Routes_LocationBrochure.res.js";
import * as LocationBrochureShowContentScss from "./LocationBrochureShowContent.scss";

var css = LocationBrochureShowContentScss;

var initialState = {
  showSignIn: false
};

function reducer(state, action) {
  return {
          showSignIn: !state.showSignIn
        };
}

function LocationBrochureShowContent(props) {
  var userLoginStatus = props.userLoginStatus;
  var show = props.show;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var tmp;
  tmp = props.userRole === "Admin" ? JsxRuntime.jsx(Breadcrumb.make, {
          breadcrumbLinks: [
            {
              linkName: "Home",
              linkPath: "/"
            },
            {
              linkName: "All Data Center Brochures",
              linkPath: Routes_LocationBrochure.index
            },
            {
              linkName: show.locationBrochure.title,
              linkPath: Routes_LocationBrochure.show(show.locationBrochure.slug)
            },
            {
              linkName: "Edit",
              linkPath: Routes_LocationBrochure.Dashboard.edit(show.locationBrochure.id)
            }
          ],
          wrapperContainerClassName: css.breadcrumbs
        }) : JsxRuntime.jsx(Breadcrumb.make, {
          breadcrumbLinks: [
            {
              linkName: "Home",
              linkPath: "/"
            },
            {
              linkName: "All Data Center Brochures",
              linkPath: Routes_LocationBrochure.index
            },
            {
              linkName: show.locationBrochure.title,
              linkPath: Routes_LocationBrochure.show(show.locationBrochure.slug)
            }
          ],
          wrapperContainerClassName: css.breadcrumbs
        });
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Data Center Brochure: " + show.location.name,
                              className: css.sectionTitle
                            }),
                        JsxRuntime.jsx(H1.make, {
                              className: css.title,
                              children: show.locationBrochure.title
                            }),
                        JsxRuntime.jsx(H2.make, {
                              className: css.subtitle,
                              children: show.locationBrochure.subtitle
                            })
                      ],
                      className: css.heading
                    }),
                JsxRuntime.jsx("div", {
                      className: css.description,
                      dangerouslySetInnerHTML: {
                        __html: show.locationBrochure.description
                      }
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "MD",
                            color: "Teal",
                            disabled: show.locationBrochure.pdfFile === "",
                            visuallyDisabled: show.locationBrochure.pdfFile.length === 0,
                            onClick: (function (param) {
                                if (userLoginStatus === "LoggedIn") {
                                  var locationBrochure = show.locationBrochure;
                                  return $$Promise.wait(Api_LocationBrochure.notifyDownload(locationBrochure.id), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return Url.visitBlank(locationBrochure.privateDownload);
                                                } else {
                                                  return SentryLogger.error1({
                                                              rootModule: "LocationBrochureShowContent",
                                                              subModulePath: /* [] */0,
                                                              value: "make",
                                                              fullPath: "LocationBrochureShowContent.make"
                                                            }, "LocationBrochureShowContent" + "::LocationBrochure::notifyDownload::Error", [
                                                              "Error",
                                                              x._0
                                                            ]);
                                                }
                                              }));
                                } else {
                                  return dispatch("ToggleSignInModal");
                                }
                              }),
                            children: "Download Brochure"
                          }),
                      className: css.download
                    }),
                match[0].showSignIn ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            dispatch("ToggleSignInModal");
                          }),
                        callback: (function () {
                            
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign in to download a Location Brochure."
                      }) : null
              ],
              className: css.content
            });
}

var make = LocationBrochureShowContent;

export {
  css ,
  initialState ,
  reducer ,
  make ,
}
/* css Not a pure module */
