// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateLocationLeadRequest from "./requests/CreateLocationLeadRequest.res.js";

var create = CreateLocationLeadRequest.exec;

export {
  create ,
}
/* CreateLocationLeadRequest Not a pure module */
