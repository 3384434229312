// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Role from "../../../models/Role.res.js";
import * as User from "../../../models/User.res.js";
import * as LocationBrochure from "../../../models/LocationBrochure.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationBrochureContact from "./components/contact/LocationBrochureContact.res.js";
import * as LocationBrochureShowScss from "./LocationBrochureShow.scss";
import * as LocationBrochureShowContent from "./components/content/LocationBrochureShowContent.res.js";
import * as LocationBrochureShowSidebar from "./components/sidebar/LocationBrochureShowSidebar.res.js";

var css = LocationBrochureShowScss;

function LocationBrochureShow$LocationBrochureShow(props) {
  var show = props.show;
  var userRole = Role.fromString(props.userRole);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(LocationBrochureShowSidebar.make, {
                            show: show
                          }),
                      className: css.sidebarContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(LocationBrochureShowContent.make, {
                            show: show,
                            userLoginStatus: props.userLoginStatus,
                            userRole: userRole,
                            setUserData: props.setUserData
                          }),
                      className: css.mainContentContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(LocationBrochureContact.make, {
                            provider: show.provider,
                            location: show.location
                          }),
                      className: css.contactUsContainer
                    })
              ],
              className: css.container
            });
}

var LocationBrochureShow = {
  css: css,
  make: LocationBrochureShow$LocationBrochureShow
};

function LocationBrochureShow$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(LocationBrochureShow$LocationBrochureShow, {
              show: LocationBrochure.Show.fromJs(props$1.show),
              userLoginStatus: User.LoginStatus.fromBool(props.reduxProps.isUserLoggedIn),
              setUserData: props.reduxfunction.setUserData,
              userRole: props$1.userRole
            });
}

var $$default = LocationBrochureShow$default;

export {
  LocationBrochureShow ,
  $$default as default,
}
/* css Not a pure module */
